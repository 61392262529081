<template>
  <div class="404">
    <b-row class="p-0 m-0 h-100 w-100">
      <b-col sm="3" xl="2" class="p-0 d-flex">
        <b-container fluid class="sidepanel p-3 d-flex flex-column">
          <!-- Empty sidepanel -->
        </b-container>
      </b-col>
      <b-col sm="9" xl="10" class="h-100 p-0">
        <b-container fluid class="page-content">
          <b-row class="page-header border-bottom">
            <b-col>
              <b-row>
                <!-- Just for consistent spacing -->
                <b-breadcrumb></b-breadcrumb>
              </b-row>
              <b-row>
                <div class="page-title">
                  404 - Page not found
                </div>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              404 - Page not found
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  export default {
    name: 'NotFound',
    data () {
      return {
      }
    },
    mounted () {
      document.title = '404 | PBFC'
    }
  }
</script>

<style lang="scss" scoped>
</style>
